import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";
import Feature from "../sections/pricing/Feature";
import PricingTable1 from "../sections/pricing/PricingTable1";

const Pricing1 = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Hero title="Find the pricing plan that fits your organization">
        </Hero>
        <PricingTable1 />
        <Feature />
      </PageWrapper>
    </>
  );
};
export default Pricing1;
